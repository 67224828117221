import React, { useState } from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CampaignIcon from '@mui/icons-material/Campaign';
import RedeemIcon from '@mui/icons-material/Redeem';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BottomMenu = ({ activeMenu = 'news' }) => {
    const [bottomNavigation, setBottomNavigation] = useState(activeMenu);
	const { t } = useTranslation();
	const navigate = useNavigate();

    const changeBottomNavigation = (event, newValue) => {
		setBottomNavigation(newValue);
		if(newValue === 'news')
		{
			navigate('/')
		}
		else
		{
			navigate("/schemes");
		}
	}

	return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, '@media (min-width: 800px)': { display: 'none' } }} elevation={3}>
            <BottomNavigation
                showLabels
                value={bottomNavigation}
                onChange={changeBottomNavigation}
                sx={{
                    '& .MuiBottomNavigationAction-root.Mui-selected' : {
                        color: '#7bc15f',
                    }
                }}
            >
                <BottomNavigationAction 
                    label={ t('menu.News') } 
                    icon={<CampaignIcon />}
                    value={'news'}
                />
                <BottomNavigationAction 
                    label={ t('menu.Schemes') } 
                    icon={<RedeemIcon />}
                    value={'schemes'}
                />
            </BottomNavigation>
        </Paper>
    );
};

export default BottomMenu;