import { React, Suspense, lazy } from "react";
import "./NavBar.css";
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { styled } from '@mui/material/styles';
import CampaignIcon from '@mui/icons-material/Campaign';
import RedeemIcon from '@mui/icons-material/Redeem';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const HamburgerDrawer = lazy(() => import('./HamburgerDrawer/HamburgerDrawer'));

const AntMenuItem = styled(MenuItem)({
	margin: '0 10px',
	'&.Mui-selected': {
		backgroundColor: '#7bc15f',
		color: 'white',
		'& .MuiListItemIcon-root': {
			color: 'white',
		},
		'&:hover': {
			backgroundColor: '#7bc15f',
		}
	},
	'&:hover': {
		backgroundColor: '#7bc15f',
		color: 'white',
		'& .MuiListItemIcon-root': {
			color: 'white',
		}
	}
});

const NavBar = ( { setCategory, setLanguage, defaultLanguage, navigate = null, showMenu = false, activeMenu = 'home' } ) => {
	const { t } = useTranslation();

	return (
		<div className="nav">
			<a href="https://utkrishi.com">
				<img
					style={{ cursor: "pointer" }}
					src="/Utkrishi Logo.png"
					alt="logo"
					className="logo"
					onClick={() => setCategory('')}
				/>
			</a>
			<div className="icon"></div>
			{
				showMenu
				?
				(<Suspense fallback={<div>Loading...</div>}><HamburgerDrawer setCategory={setCategory} setLanguage={setLanguage} defaultLanguage={defaultLanguage}/></Suspense>)
				:
				(<Button onClick={navigate}><ArrowBackIcon/></Button>)
			}
			<div className="menu">
				<MenuList style={{ display: 'flex' }}>
					<NavLink to={'/'} style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem selected={activeMenu == 'home'}>
							<ListItemIcon>
								<CampaignIcon />
							</ListItemIcon>
							<ListItemText>{ t('menu.News') }</ListItemText>
						</AntMenuItem>
					</NavLink>
					<NavLink to={'/schemes'} style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem selected={activeMenu == 'schemes'}>
							<ListItemIcon>
								<RedeemIcon />
							</ListItemIcon>
							<ListItemText>{ t('menu.Schemes') }</ListItemText>
						</AntMenuItem>
					</NavLink>
				</MenuList>
			</div>
		</div>
	);
};

export default NavBar;
