import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppRoutes from "./routes/AppRoutes";
import './util/i18n/i18n.js';

ReactDOM.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
