import React from "react";
import "./Footer.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';

const Footer = () => {
	return (
	<div className="footer">
		<div className="iconContainer">
			<a href="https://www.facebook.com/UtkrishiIndia/" target="__blank">
				<FacebookIcon style={{ color:'black', fontSize:'2rem' }} />
			</a>
			<a href="https://www.instagram.com/utkrishi_india_official" target="__blank">
				<InstagramIcon style={{ color:'black', fontSize:'2rem' }} />
			</a>
			<a href="https://www.youtube.com/channel/UCjhomR4M_iwVqNEBl7j4usA" target="__blank">
				<YouTubeIcon style={{ color:'black', fontSize:'2rem' }} />
			</a>
			<a href="https://t.me/UtkrishiIndia" target="__blank">
				<TelegramIcon style={{ color:'black', fontSize:'2rem' }} />
			</a>
		</div>
	</div>
  );
};

export default Footer;