import React from "react";
import { Navigate } from "react-router-dom";

const UserProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('userToken') ? true : false;

  return isAuthenticated ? children : <Navigate to="/sign-in" />;
};

export default UserProtectedRoute;
