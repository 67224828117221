import axios from "axios";
import { React, useEffect, useState, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";

import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../util/firebase/firebaseConfig";

import "./Home.css";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/Footer/Footer";
import BottomMenu from "../../components/Footer/BottomMenu";

import { WhatsAppOutlined } from '@ant-design/icons';
import { FloatButton, Button } from 'antd';

import { toast, ToastContainer } from "react-toastify";
import Message from "../../components/Message/Message";
import "react-toastify/dist/ReactToastify.css";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

const NewsContent = lazy(() => import('../../components/NewsContent/NewsContent'));
const CategoryBar = lazy(() => import('../../components/categoryBar/CategoryBar'));

const Home = () => {
	const pageLimit = 20;
	const [open, setOpen] = useState(false);
	const [category, setCategory] = useState("");
	const [language, setLanguage] = useState("");
	const [defaultLanguage, setDefaultLanguage] = useState("");
	const [newsArray, setnewsArray] = useState([]);
	const [ads, setAds] = useState([]);
	const [newsResults, setnewsResults] = useState();
	const [loadmore, setloadmore] = useState(pageLimit);
	const [findNews, setFindNews] = useState(true);
	const [latestNews, setLatestNews] = useState(false);
	const params = useParams();

	const newsApi = async () => 
	{
		try 
		{
			if(params.title && findNews && loadmore == 20)
			{
				setFindNews(false);
				var userLang = localStorage.getItem("language") ? localStorage.getItem("language") : '';
				const findNews = await axios.get(`${process.env.REACT_APP_API_HOST}/api/find-news?title=${params.title}&language=${userLang}`);
				setnewsArray(findNews.data.results);
				setnewsResults(findNews.data.total);
				
				if(findNews.data.results.length > 0)
				{
					setDefaultLanguage(findNews.data.results[0].language);
				}
				if(!findNews.data.latest)
				{
					setLatestNews(true);
				}
			}
			else
			{
				if(!defaultLanguage)
				{
					if(localStorage.getItem('language'))
					{
						setDefaultLanguage(localStorage.getItem('language'));
					}
					else
					{
						setDefaultLanguage('en');
					}
				}
				var newsLanguage = language ? language : localStorage.getItem('language') ? localStorage.getItem('language') : '';
				const news = await axios.get(`${process.env.REACT_APP_API_HOST}/api/publish-news?category=${category}&pageSize=${loadmore}&language=${newsLanguage}`);
				if(pageLimit !== loadmore)
				{
					news.data.results.map(result => setnewsArray(newsArray => [...newsArray,result]));
				}
				else
				{
					setnewsArray(news.data.results);
				}
				setnewsResults(news.data.total);
			}
		}
		catch (error) 
		{
			console.log(error);
		}
	};
	const AdsApi = async () => 
	{
		try 
		{
			const ads = await axios.get(`${process.env.REACT_APP_API_HOST}/api/publish-ads?page_no=${loadmore/pageLimit}`);
			setAds(ads.data.results);
		} 
		catch (error) 
		{
			console.log(error);
		}
	}
	useEffect(() => 
	{
		newsApi();
		AdsApi();
		setFindNews(false);
	}, [category, language, loadmore]);

	useEffect(() =>
	{
		const requestPermission = async() =>
		{
			try 
			{
				//requesting permission using Notification API
				const permission = await Notification.requestPermission();
				if (permission === "granted") 
				{
					const token = await getToken(messaging, {vapidKey: process.env.REACT_APP_VITE_VAPID_KEY,});
					//We can send token to server
					console.log("Token generated : ", token);
					await axios.post(`${process.env.REACT_APP_API_HOST}/api/firebase_subscription`,{subscription_id: token, subscription_language : localStorage.getItem('language')});
				} 
				else if (permission === "denied") 
				{
					//notifications are blocked
					console.log("You denied for the notification");
				}
			}
			catch (error) 
			{
				if (error.response) 
				{
					console.log(error.response.data);
					console.log(error.response.status);
				} 
				else if (error.request) 
				{
					console.log(error.request);
				} 
				else 
				{
					console.log('Error', error.message);
				}	
			}
		}
		requestPermission();
		window.history.scrollRestoration = 'manual';
		const openPreferredLangModal = (event) => {
			if (!localStorage.getItem('language') && !params.title) {
			  if (window.scrollY >= 600) {
				setOpen(true);
			  }
			}
		};
		window.addEventListener('scroll', openPreferredLangModal, true);
	},[]);

	const setLan = (Language) =>{
		setLatestNews(false);
		setloadmore(pageLimit);
		setLanguage(Language);
		setCategory('');
		window.scrollTo(0, 0);
	}

	const setCat = (Category) =>{
		setLatestNews(false);
		setloadmore(pageLimit);
		setCategory(Category);
		window.scrollTo(0, 0);
	}

	const WhatsAppChannel = () => {
		window.open('https://www.whatsapp.com/channel/0029VaVHO2eFCCoS5Pkwyp0g','_blank')
	}

	const handleListItemClick = (value) => {
		localStorage.setItem('language',value);
		document.cookie = "googtrans=/en/"+value;
		setDefaultLanguage(value);
		setLanguage(value);
		setOpen(false);
		window.scrollTo(0, 0);
	};

	const handleClose = (event, reason) => {
		if (reason && reason === "backdropClick") 
        return;
	}

	onMessage(messaging, (payload) => 
	{
		toast(<Message notification={payload.data} />,{
			position: "bottom-right",
			autoClose: false,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: true,
			theme: "light",
		});
	});

  return (
	<div>
		<NavBar setCategory={setCat} setLanguage={setLan} defaultLanguage={defaultLanguage} showMenu={true} />
		<Suspense fallback={<div>Loading...</div>}>
			<CategoryBar setCategory={setCat} />
		</Suspense>
		<div className="App" translate="no">
			{
				latestNews ?
				(
					<Button type="primary" shape="round" className="latest-news-btn" onClick={() => { newsApi(); setLatestNews(false); window.scrollTo(0, 0); } }>
						See Latest News
         			</Button>
				)
				:
				''
			}
			<Suspense fallback={<div>Loading...</div>}>
				<NewsContent
					pageLimit={pageLimit}
					setloadmore={setloadmore}
					loadmore={loadmore}
					newsArray={newsArray}
					newsResults={newsResults}
					adsArray={ads}
				/>
			</Suspense>
		</div>
		<BottomMenu />
		<Footer />
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">				
				पसंदीदा भाषा चुनें
				<br/>
				Select Preferred Language
			</DialogTitle>
			<List>
				<ListItem button onClick={() => handleListItemClick('hi')} key={'हिन्दी'}>
					<ListItemText primary={'हिन्दी'} />
				</ListItem>
				<ListItem button onClick={() => handleListItemClick('mr')} key={'मराठी'}>
					<ListItemText primary={'मराठी'} />
				</ListItem>
				<ListItem button onClick={() => handleListItemClick('en')} key={'English'}>
					<ListItemText primary={'English'} />
				</ListItem>
			</List>
		</Dialog>
		<FloatButton
			shape="circle"
			type="success"
			style={{ right: 10, bottom: 60, width:45,height:45, backgroundColor: 'rgb(142 196 71)' }}
			onClick={WhatsAppChannel}
			icon={<WhatsAppOutlined style={{color:'white',fontSize: '25px',}} />}
		/>
		<ToastContainer />
	</div>
    
  );
};

export { Home };
