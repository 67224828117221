import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: process.env.REACT_APP_VITE_API_KEY,
  authDomain: process.env.REACT_APP_VITE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_VITE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_VITE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_VITE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_VITE_APP_ID,
  measurementId: process.env.REACT_APP_VITE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);