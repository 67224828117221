import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from './translation/en.json'
import hiJSON from './translation/hi.json'
import mrJSON from './translation/mr.json'

i18n.use(initReactI18next).init({
    resources: {
        en: { ...enJSON },
        hi: { ...hiJSON },
        mr: { ...mrJSON },
    },
    lng:  localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    }
});